import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { forwardRef, MouseEvent, useState } from 'react';
import {
  Author,
  ButtonGroup,
  ButtonGroupContainer,
  FullTranslation,
  HaikuText,
  HaikuWrapper,
  IconButton,
  StyledHoverableRuby,
  StyledUnderline,
  TokenTranslation,
} from './App.styles';
import { BASE_URL } from './config';
import { useAudioContextManger } from './contexts/AudioContext';
import { HaikuProps, JaAudio, JapaneseTextMode, Token } from './Haiku.types';

const Haiku = forwardRef<HTMLDivElement, HaikuProps>(({ haiku }, ref) => {
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const [jaAudio, setJaAudio] = useState<JaAudio>('original');
  const [japaneseTextMode, setJapaneseTextMode] =
    useState<JapaneseTextMode>('hiragana');
  const audioContextManger = useAudioContextManger();
  let currentSource: AudioBufferSourceNode | null = null;

  const playAudio = async (soundUrl: string) => {
    if (!audioContextManger) return;
    const { soundEnabledRef, initAudioContext, audioContextRef } =
      audioContextManger;

    if (!audioContextRef.current) initAudioContext();

    try {
      if (
        soundEnabledRef &&
        soundEnabledRef.current &&
        audioContextRef.current
      ) {
        const response = await fetch(soundUrl);
        const audioData = await response.arrayBuffer();
        const audioContext = audioContextRef.current;
        const buffer = await audioContext.decodeAudioData(audioData);
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);

        if (currentSource) {
          currentSource.stop();
        }

        currentSource = source;

        source.start(0);
      }
    } catch (error) {
      console.error('Error playing audio:', error);
    }
  };

  const handleRubyClick = async (
    _: MouseEvent<HTMLSpanElement>,
    index: number,
    soundUrl: string,
  ) => {
    setClickedIndex(index === clickedIndex ? null : index);
    playAudio(soundUrl);
  };

  const handleImageClick = async (soundUrl: string) => {
    playAudio(soundUrl);
  };

  const renderRuby = (token: Token, index: number) => (
    <StyledHoverableRuby
      key={index}
      $isActive={clickedIndex === index}
      onClick={(event) =>
        handleRubyClick(
          event,
          index,
          `${BASE_URL}${
            jaAudio === 'original' ? token.sound_url : token.hiragana_sound_url
          }`,
        )
      }
    >
      {token.original}
      <rt>
        <StyledUnderline $isActive={jaAudio !== 'original'}>
          {token[japaneseTextMode]}
        </StyledUnderline>
      </rt>
    </StyledHoverableRuby>
  );

  const modes: JapaneseTextMode[] = ['hiragana', 'romaji', 'katakana'];
  const handleToggleJaTextMode = () => {
    setJapaneseTextMode((prevMode) => modes[(modes.indexOf(prevMode) + 1) % 3]);
  };
  const handleToggleJaAudio = () => {
    setJaAudio((prevJaAudio) =>
      prevJaAudio === 'original' ? 'hiragana' : 'original',
    );
  };

  return (
    <div ref={ref} className="haiku">
      <img
        src={`${BASE_URL}${haiku.image_url}`}
        alt={haiku.full_text}
        onClick={() => handleImageClick(`${BASE_URL}${haiku.sound_url}`)}
      />
      <HaikuText>
        <HaikuWrapper>{haiku.tokens.map(renderRuby)}</HaikuWrapper>
        <LayoutGroup>
          <AnimatePresence>
            {clickedIndex === null ? (
              <motion.div
                key="full-translation"
                layout
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5, ease: 'linear' }} // Adjust duration and easing
                className="full-translation"
              >
                <FullTranslation>{haiku.translated}</FullTranslation>
              </motion.div>
            ) : (
              <motion.div
                key="token-translation"
                layout
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5, ease: 'linear' }} // Adjust duration and easing
                className="token-translation"
              >
                <TokenTranslation>
                  {haiku.tokens[clickedIndex].translation}
                </TokenTranslation>
              </motion.div>
            )}
          </AnimatePresence>
        </LayoutGroup>
      </HaikuText>
      <ButtonGroupContainer>
        <ButtonGroup>
          <IconButton onClick={handleToggleJaTextMode}>
            {japaneseTextMode === 'hiragana' && <span>A</span>}
            {japaneseTextMode === 'romaji' && <span>カ</span>}
            {japaneseTextMode === 'katakana' && <span>ひ</span>}
          </IconButton>
          <IconButton onClick={handleToggleJaAudio}>
            {jaAudio === 'hiragana' && <span>音</span>}
            {jaAudio === 'original' && <span>訓</span>}
          </IconButton>
        </ButtonGroup>

        <Author>
          <span>{haiku.author}</span>
        </Author>
      </ButtonGroupContainer>
    </div>
  );
});

export default Haiku;
