import { useCallback, useEffect, useState } from 'react';
import {
  FaCoffee,
  FaSpinner,
  FaTwitter,
  FaVolumeMute,
  FaVolumeUp,
} from 'react-icons/fa';
import {
  AppContainer,
  GlobalStyle,
  HaikuItem,
  HaikuList,
  IconButton,
  IconButtonSpan,
  PageInfo,
  PaginationWrapper,
  Spinner,
} from './App.styles';
import { BASE_URL } from './config';
import { useAudioContextManger } from './contexts/AudioContext';
import Haiku from './Haiku';
import { HaikuData } from './Haiku.types';
import { useLocation, useNavigate } from 'react-router-dom';
import { numberToKanji } from './utils/helper';
import Footer from './Footer';
import Header from './Header';

function App() {
  const [haikus, setHaikus] = useState<HaikuData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [haikuCache, setHaikuCache] = useState<HaikuData[][]>([]);
  const [visitedPages, setVisitedPages] = useState<Set<number>>(new Set());
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    if (!visitedPages.size) return;
    if (page) {
      setCurrentPage(Number(page));
    }
  }, [location]);

  const goToPage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      navigate(`?page=${page}`);
    }
  };

  useEffect(() => {
    const fetchHaikus = async () => {
      if (!visitedPages.has(currentPage)) {
        setLoading(true);
        try {
          const response = await fetch(
            `${BASE_URL}/haikus?page=${currentPage}`,
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setTotalPages(data.totalPages); // Assuming the fetched data contains a totalPages property
          setHaikus(data.haikus); // Assuming the fetched data contains a haikus property
          setVisitedPages((prev) => new Set(prev).add(currentPage)); // Add currentPage to visited pages
          setHaikuCache((prev) => [...prev, data.haikus]);
        } catch (error) {
          setError((error as Error).message);
        } finally {
          setLoading(false);
        }
      } else {
        setHaikus(haikuCache[currentPage - 1]);
      }
    };

    fetchHaikus();
  }, [currentPage, visitedPages]);

  const audioContextManger = useAudioContextManger();

  const [soundOn, setSoundOn] = useState<boolean>(false);

  const toggleSound = useCallback(() => {
    if (!audioContextManger) return;
    const { soundEnabledRef } = audioContextManger;
    audioContextManger?.initAudioContext();
    setSoundOn((prevSoundOn: boolean) => {
      soundEnabledRef.current = !prevSoundOn;
      return !prevSoundOn;
    });
  }, []);

  if (loading) {
    return (
      <Spinner>
        <FaSpinner />
      </Spinner>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <GlobalStyle />
      <Header />
      <AppContainer>
        <HaikuList>
          {haikus.map((haiku) => (
            <HaikuItem key={haiku.id}>
              <Haiku haiku={haiku} />
            </HaikuItem>
          ))}
        </HaikuList>
        <PaginationWrapper>
          <IconButton
            onClick={() => goToPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            前
          </IconButton>
          <PageInfo>{numberToKanji(currentPage)}</PageInfo>
          <IconButton
            onClick={() => goToPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            次
          </IconButton>
          <IconButton onClick={toggleSound}>
            {soundOn ? (
              <IconButtonSpan>
                <FaVolumeMute />
              </IconButtonSpan>
            ) : (
              <IconButtonSpan>
                <FaVolumeUp />
              </IconButtonSpan>
            )}
          </IconButton>
          <IconButton>
            <a
              href="https://x.com/YongGuo134745"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Twitter"
            >
              <IconButtonSpan>
                <FaTwitter />
              </IconButtonSpan>
            </a>
          </IconButton>
          <IconButton>
            <a
              href="https://buy.stripe.com/dR6cQIdsubME7zadQQ"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Donate"
            >
              <IconButtonSpan>
                <FaCoffee />
              </IconButtonSpan>
            </a>
          </IconButton>
        </PaginationWrapper>
      </AppContainer>
      <Footer />
    </>
  );
}

export default App;
