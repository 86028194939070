import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #e0e6ec;
  width: 100%;
  color: #555; // Darker gray text color for better contrast
  font-family: 'Arial', serif; // Use the same font-family as the global style
  font-size: 0.7rem;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
`;

const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <Info>
        <span>
          We transform wellness with technology. Email us:{' '}
          <a href="mailto:yong@yvv.ai">yong@yvv.ai</a>
        </span>
      </Info>
    </FooterWrapper>
  );
};

export default Footer;
