export const numberToKanji = (number: number): string => {
  const kanjiDigits = [
    '',
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
  ];
  const kanjiTens = ['', '十', '百', '千'];

  let kanji = '';
  const digits = String(number).split('').reverse();

  for (let i = 0; i < digits.length; i++) {
    const digit = parseInt(digits[i], 10);
    if (digit !== 0) {
      kanji =
        (i > 0 && digit === 1 ? '' : kanjiDigits[digit]) + kanjiTens[i] + kanji;
    }
  }

  return kanji || '零'; // Return '零' for zero
};
