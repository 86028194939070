import styled, { css, keyframes, createGlobalStyle } from 'styled-components';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: serif;
    background-color: #f0f4f8;
    color: #333;
    margin: 0;
    padding: 0;
    line-height: 1.6;
  }

  h1 {
    text-align: center;
    margin-top: 20px;
    color: #444;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ruby {
    display: inline-block;
    position: relative;
    font-size: 1.5em;
    font-family: 'Hannari', 'Sawarabi Mincho', 'Noto Serif JP', serif;
  }

  rt {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    font-size: 0.5em;
    line-height: 1;
    text-align: center;
  }
`;

export const AppContainer = styled.div`
  padding: 20px;
`;

export const HaikuList = styled.div`
  list-style-type: none;
  padding: 0;
`;

export const HaikuItem = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 600px;
  padding: 20px;
  transition: transform 0.2s;
  user-select: none;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
`;

export const HaikuText = styled.div`
  margin: 10px 0;
  margin-block-start: 1em;
  padding: 0;
  font-size: 16px;
  color: #555;
`;

export const HaikuWrapper = styled.div``;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  margin: 0 5px;
`;

export const IconButton = styled(Button)`
  cursor: pointer;
  font-size: 1em;
  min-width: 2.5em;
  height: 2.5em;
`;

export const IconButtonSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonGroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const Author = styled.div`
  font-family: 'Hannari', 'Sawarabi Mincho', 'Noto Serif JP', serif;
  margin-left: auto;
  text-align: right;
  padding-right: 1.5rem;
`;

export const StyledUnderline = styled.span<{ $isActive: boolean }>`
  ${({ $isActive }) =>
    $isActive &&
    css`
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 2px;
      text-decoration-color: rgba(0, 0, 0, 0.2);
    `}
`;

export const PageInfo = styled.span`
  margin: 0 10px;
`;

export const Spinner = styled.div`
  animation: ${spin} 1s linear infinite;
  font-size: 4rem;
  color: #8a8a8a;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const HaikuAuthor = styled.div`
  text-align: right;
  padding-right: 10px;
  margin-top: 10px;
  font-size: 1em;
`;

export const StyledHoverableRuby = styled.ruby<{ $isActive: boolean }>`
  /* Common styles for both inactive-ruby and active-ruby */
  cursor: pointer;

  /* Styles for active-ruby */
  ${({ $isActive }) =>
    $isActive &&
    `
    color: red;
  `}

  /* Styles for inactive-ruby */
  ${({ $isActive }) =>
    !$isActive &&
    `
    color: black;
  `}
`;

export const FullTranslation = styled.div`
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  color: #333;
`;

export const TokenTranslation = styled.div`
  font-family: 'Noto Sans', sans-serif;
  font-size: 16px;
  color: #555;
  font-style: italic;
`;
