import React from 'react';
import styled from 'styled-components';

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  background-color: white;
  color: #333;
  width: 100%;
`;

const Logo = styled.div`
  border-radius: 50%;
  height: 100%;
  display: flex;
  margin-right: 0.5rem; // Increase margin to create more space between logo and title
`;

const Title = styled.div`
  font-family: 'Hannari', 'Sawarabi Mincho', Noto Serif JP', serif;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 1rem;
  ruby {
    font-family: 'Courier', sans-serif;
    rt {
      font-family: 'Hannari'
    }
  }
`;

const Header: React.FC = () => {
  return (
    <HeaderWrapper>
      <Logo>
        <img
          src={process.env.PUBLIC_URL + '/images/logo.png'}
          alt="Logo"
          height="100"
        />
      </Logo>
      <Title>
        <ruby>
          Haiku Sensei<rt>ハイクセンセイ</rt>
        </ruby>
      </Title>
    </HeaderWrapper>
  );
};

export default Header;
