import React, { createContext, ReactNode, useContext, useRef } from 'react';

interface AudioContextManagerType {
  initAudioContext: () => void;
  audioContextRef: React.MutableRefObject<AudioContext | null>;
  soundEnabledRef: React.MutableRefObject<boolean>;
}

interface AudioContextProviderProps {
  children: ReactNode;
}

const AudioContextManager = createContext<AudioContextManagerType | null>(null);

export const useAudioContextManger = () => useContext(AudioContextManager);

export const AudioContextManagerProvider: React.FC<
  AudioContextProviderProps
> = ({ children }) => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const soundEnabledRef = useRef<boolean>(false);

  const initAudioContext = () => {
    if (audioContextRef.current) return;
    const context = new AudioContext();
    if (context.state === 'suspended') {
      context.resume();
    }
    audioContextRef.current = context;
  };

  return (
    <AudioContextManager.Provider
      value={{
        initAudioContext,
        audioContextRef,
        soundEnabledRef,
      }}
    >
      {children}
    </AudioContextManager.Provider>
  );
};
